export default {
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
  RESET_SCALE: 'RESET_SCALE',
  ADD_TODAY_REF: 'ADD_TODAY_REF',
  ADD_BOARD_REF: 'ADD_BOARD_REF',

  INSERT_ACTIVITY: 'INSERT_ACTIVITY',
  UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
  DELETE_ACTIVITY: 'DELETE_ACTIVITY',

  CREATE_RECURRING_ACTIVITY: 'CREATE_RECURRING_ACTIVITY',
  UPDATE_RECURRING_ACTIVITY: 'UPDATE_RECURRING_ACTIVITY',
  DELETE_RECURRING_ACTIVITY: 'DELETE_RECURRING_ACTIVITY',

  REMOVE_EMPLOYEE: 'REMOVE_EMPLOYEE',
  SEARCH_EMPLOYEES: 'SEARCH_EMPLOYEES',

  REMOVE_ALL_ACTIVITIES: 'REMOVE_ALL_ACTIVITIES',
  FETCH_UNPLANNED_WORK_ORDERS: 'FETCH_UNPLANNED_WORK_ORDERS',
  SAVE_ACTIVITIES_AND_EMPLOYEES: 'SAVE_ACTIVITIES_AND_EMPLOYEES',
  FETCH_ACTIVITIES_BY_SCROLLING: 'FETCH_ACTIVITIES_BY_SCROLLING',

  CHANGE_METHOD_FILTER: 'CHANGE_METHOD_FILTER',
  CHANGE_LOCATION_FILTER: 'CHANGE_LOCATION_FILTER',
  CHANGE_CUSTOMER_FILTER: 'CHANGE_CUSTOMER_FILTER',
  CHANGE_ESTABLISHMENT_FILTER: 'CHANGE_ESTABLISHMENT_FILTER',

  GET_WORK_ORDERS: 'GET_WORK_ORDERS',
  SEARCH_WORK_ORDERS: 'SEARCH_WORK_ORDERS',
  FETCH_OUTSIDE_DATE: 'FETCH_OUTSIDE_DATE',
  GET_ACTIVITIES_TYPES: 'GET_ACTIVITIES_TYPES',
  UPDATE_EMPLOYEES_ORDER: 'UPDATE_EMPLOYEES_ORDER',
  GET_WORK_ORDER_FILTERS: 'GET_WORK_ORDER_FILTERS',
  SEARCH_UNPLANNED_ORDERS: 'SEARCH_UNPLANNED_ORDERS',
  СHANGE_WO_LOCATION_FILTER: 'СHANGE_WO_LOCATION_FILTER',
  СHANGE_WO_CUSTOMER_FILTER: 'СHANGE_WO_CUSTOMER_FILTER',

  REMOVE_UNPLANNED_WORK_ORDER: 'REMOVE_UNPLANNED_WORK_ORDER',
  UPDATE_UNPLANNED_WORK_ORDERS: 'UPDATE_UNPLANNED_WORK_ORDERS',

  CHANGE_WO_ESTABLISHMENT_FILTER: 'CHANGE_WO_ESTABLISHMENT_FILTER',

  UPDATE_DAY_SUMMARY: 'UPDATE_DAY_SUMMARY',
  UPDATE_DAYS_SUMMARY: 'UPDATE_DAYS_SUMMARY',

  DELETE_ALL_FILES: 'DELETE_ALL_FILES',
  SET_ACTIVE_EMPLOYEE: 'SET_ACTIVE_EMPLOYEE',
  SET_IS_FILE_LOADING: 'SET_IS_FILE_LOADING',
  ADD_ATTACHED_FILE_ID: 'ADD_ATTACHED_FILE_ID',
  DELETE_ATTACHED_FILE_ID: 'DELETE_ATTACHED_FILE_ID',

  UPDATE_NP_PROGRESS_STATUS: 'UPDATE_NP_PROGRESS_STATUS',

  EDIT_ACTIVITY: 'EDIT_ACTIVITY',

  LOGOUT: 'LOGOUT',
  RELOGIN: 'RELOGIN',

  UPDATED_WORK_ORDER_NOTE: 'UPDATED_WORK_ORDER_NOTE',
};
