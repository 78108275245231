import axios from './axios';

import { saveNote, editNote, deleteNote } from './workOrder';

// const saveNote = payload => axios.post('planning/notes/', payload);

const verifyUserAuth = () => axios('planning/key/').then(({ data }) => data);
//
const getProjectTags = () =>
  axios('planning/projects/tags/').then(({ data }) => data);

const updateDaysSummary = (startPeriod, endPeriod, activeEmployees) =>
  axios
    .post('planning/activities/daily-metadata/', {
      date_from: startPeriod,
      date_to: endPeriod,
      employee_ids: activeEmployees,
    })
    .then(({ data }) => data);

// INITIAL REQUEST TO GET ALL DISPLAYING DATA ON CALENDAR
const fetchActivitiesAndEmployees = ({ date, offset, days }) =>
  axios
    .get('planning/activities/', {
      params: {
        date,
        offset,
        days,
      },
    })
    .then(({ data }) => data);

// API FOR WORK WITH ACTIVITIES
const fetchOnlyActivities = ({ date, offset, days }) =>
  fetchActivitiesAndEmployees({ date, offset, days });

const postActivity = reqObj =>
  axios.post('planning/activities/save/', reqObj).then(({ data }) => data);

const deleteActivity = id => axios.post('planning/activities/delete/', { id });

const fetchActivitiesTypes = () =>
  axios.get('planning/activities/types/').then(({ data }) => data);

// API FOR WORK WITH WORK ORDERS
const fetchWorkOrders = () =>
  axios.get('non-invoiced-work-orders/').then(({ data }) => data);

const fetchWorkOrderData = id =>
  axios
    .get(`planning/activities/get-extended-work-order-data/${id}/`)
    .then(({ data }) => data);

const fetchWorkOrdersType = () =>
  axios.get('planning/activities/types/').then(({ data }) => data);

const deleteEmployee = reqObj =>
  axios
    .post('planning/activities/remove-employee-from-work-order/', reqObj)
    .then(({ data }) => data);

const deleteAllAcitivities = reqObj =>
  axios
    .post('planning/activities/remove-all-activities-from-work-order/', reqObj)
    .then(({ data }) => data);

const fetchUnplannedWorkOrders = () =>
  axios.get('planning/non-planned-work-orders/').then(({ data }) => data);

// API FOR EMPLOYEE SORTING
const saveEmployeesOrder = positionMap =>
  axios.post('planning/employees/sort/', positionMap).then(({ data }) => data);

// API FOR RECURRENCE ACTION
const createRecurrence = pattern =>
  axios
    .post('planning/recurring-activities/create/', pattern)
    .then(({ data }) => data);

const updateRecurrence = (id, pattern) =>
  axios
    .post(`planning/recurring-activities/update/${id}/`, pattern)
    .then(({ data }) => data);

const deleteRecurrence = id =>
  axios.post(`planning/recurring-activities/delete/${id}/`);

const getRecurrence = id =>
  axios.get(`planning/recurring-activities/${id}/`).then(({ data }) => data);

// API FOR WORK ORDER CREATION
const getCustomers = () => axios.get('customers/').then(({ data }) => data);

const postCustomer = payload =>
  axios.post('planning/customers/create/', payload).then(({ data }) => data);

const getProjects = customerId =>
  axios
    .get(`planning/projects/customer/${customerId}/`)
    .then(({ data }) => data);

const getProject = projectId =>
  axios.get(`planning/projects/${projectId}/`).then(({ data }) => data);

const postProject = payload =>
  axios.post('planning/projects/create/', payload).then(({ data }) => data);

const postWorkOrder = payload =>
  axios.post('planning/work-orders/new/', payload).then(({ data }) => data);

const getProjectEstablishments = () =>
  axios
    .get('planning/get_establishments_for_project/')
    .then(({ data }) => data);

const getProjectOptions = establishmentId =>
  axios
    .get(`planning/get_data_for_project/${establishmentId}/`)
    .then(({ data }) => data);

const getProjectCustomerContacts = projectId =>
  axios
    .get(`planning/get_contacts_for_customer/${projectId}/`)
    .then(({ data }) => data);

// API FOR FILE UPLOAD
const uploadFile = (formData, onProgress, onSuccess) =>
  axios
    .post('/planning/document/create/', formData, {
      onUploadProgress: ({ total, loaded }) => {
        onProgress({
          percent: Number(Math.round((loaded / total) * 100).toFixed(2)),
        });
      },
    })
    .then(({ data }) => {
      onSuccess();
      return data;
    });

const deleteUploadFile = id =>
  axios.post(`planning/document/delete/${id}/`).then(({ data }) => data);

// API FOR PERMISSION CHECK
const checkPermission = activityId =>
  axios
    .get('planning/edit_delete_activity/check_permissions/', {
      params: {
        activity_id: String(activityId),
      },
    })
    .then(({ data }) => data.result);

const getLocationsForCustomer = customerId =>
  axios(`planning/get_locations_for_customer/${customerId}/`).then(
    ({ data }) => data
  );

const getExecutionSpecifications = projectId =>
  axios(`planning/get_execution_specifications/${projectId}/`).then(
    ({ data }) => data
  );

const getRTTubeSets = () =>
  axios('planning/get_rt_tube_sets/').then(({ data }) => data);

const getYSourceSets = date =>
  axios('planning/get_gamma_source_sets/', {
    params: {
      start_date: date,
    },
  }).then(({ data }) => data);

const saveExucationSpecification = specifications => {
  return axios
    .post(
      'planning/save_execution_specifications_for_work_order/',
      specifications
    )
    .then(({ data }) => data);
};

const API = {
  saveNote,
  editNote,
  deleteNote,
  getProjectTags,
  verifyUserAuth,
  updateDaysSummary,
  getProject,
  uploadFile,
  getProjects,
  postProject,
  getCustomers,
  postCustomer,
  postActivity,
  postWorkOrder,
  getRecurrence,
  getRTTubeSets,
  deleteActivity,
  deleteEmployee,
  getYSourceSets,
  checkPermission,
  fetchWorkOrders,
  deleteUploadFile,
  createRecurrence,
  updateRecurrence,
  deleteRecurrence,
  getProjectOptions,
  saveEmployeesOrder,
  fetchWorkOrderData,
  fetchWorkOrdersType,
  fetchOnlyActivities,
  fetchActivitiesTypes,
  deleteAllAcitivities,
  getLocationsForCustomer,
  getProjectEstablishments,
  fetchUnplannedWorkOrders,
  getExecutionSpecifications,
  getProjectCustomerContacts,
  saveExucationSpecification,
  fetchActivitiesAndEmployees,
};

export default API;
