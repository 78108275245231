import axios from './axios';

export const saveNote = payload =>
  axios.post('planning/notes/', payload).then(({ data }) => data);

export const editNote = payload =>
  axios
    .put(`planning/notes/${payload.id}/${payload.entity_type}/`, payload)
    .then(res => res);

export const deleteNote = payload =>
  axios.delete(`planning/notes/${payload.id}/work_order/`).then(res => res);
