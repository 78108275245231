import globalMutableStore from './globalMutableStore';
import t from './actionsTypes';
import {
  MAX_SCALE,
  MIN_SCALE,
  DIMENSIONS,
  SCALING_SPEED,
  OFFSET_DAY_WIDTH,
  MAX_ACTIVITIES_NUMBER,
} from '../constants/continuum';
import { isActivityInRange } from '../coreFuncs/overlaping';
import { getScaledDimensions } from '../utils/scrollBoundaries';

export const initRootState = {
  isNpProgressActive: false,
  dimensions: DIMENSIONS,
  dates: [],
  employees: [],
  allEmployees: [],
  ownEmployees: null,
  allowedEmployees: null,
  visibleEmployees: [],
  positionsMap: [],
  activities: [],
  establishmentsEmployees: null,
  activeEmployee: null,
  todayRef: null,
  boardRef: null,
  isInitReqeusting: true,
  unplannedWorkOrders: [],
  unplannedWorkOrdersLocations: [],
  unplannedWorkOrdersMethods: [],
  unplannedWorkOrdersCustomers: [],
  unplannedWorkOrdersEstablishments: [],
  locationFilters: [],
  customerFilters: [],
  isEditable: false,
  isUpdateAll: false,
  methodFilters: [],
  establishmentFilters: [],
  unplannedOrdersSearchQuery: null,
  workOrdersCustomers: [],
  workOrdersLocations: [],
  workOrdersEstablishments: [],
  workOrderLocationFilters: [],
  workOrderCustomerFilters: [],
  workOrderEstablishmentFilters: [],
  workOrdersSearchQuery: null,
  activitiesTypes: [],
  workOrders: [],
  initScrollLeft: 3958,
  scrollLeft: OFFSET_DAY_WIDTH * 11,
  scale: 1,
  maxScale: MAX_SCALE,
  minScale: MIN_SCALE,
  scalingSpeed: SCALING_SPEED,
  maxActivitiesNumber: MAX_ACTIVITIES_NUMBER,
  isFirstResponse: false,
  attachedFiles: [],
  isFileLoading: false,
};

const checkPermission = (permissions, checkedPermission) => {
  if (permissions) {
    return permissions.some(el => el.includes(checkedPermission));
  }

  return false;
};

const getAllowedEmployees = (establishment, own) => {
  if (!establishment && !own) return [];
  if (establishment) return establishment;
  if (own) return own;

  return [];
};

export const rootReducer = (state, { type, payload }) => {
  switch (type) {
    case t.UPDATE_NP_PROGRESS_STATUS:
      return {
        ...state,
        isNpProgressActive: payload,
      };
    // INITIAL ACTION FOR SAVE ALL CALENDAR DATE
    case t.SAVE_ACTIVITIES_AND_EMPLOYEES: {
      const {
        employees = [],
        activities,
        total_planned: dates,
        permissions,
        own_employees: ownEmployees,
        establishments_employees: establishmentsEmployees,
      } = payload;

      const positionsMap = {};

      let allEmployees = employees;

      const isOrderSettings =
        employees.length && typeof employees[0].visible !== 'undefined';

      if (!isOrderSettings) {
        allEmployees = employees.map((employee, i) => ({
          ...employee,
          visible: false,
          sort_order: i,
        }));
      }

      let visibleEmployees = [...allEmployees];

      if (isOrderSettings) {
        visibleEmployees = allEmployees.filter(({ visible }) => visible);
      }

      visibleEmployees.forEach(({ id }, i) => {
        positionsMap[id] = i;
      });

      return {
        ...state,
        dates,
        activities,
        positionsMap,
        visibleEmployees,
        employees: visibleEmployees,
        allEmployees,
        isUpdateAll: checkPermission(permissions, 'update.all'),
        isEditable: checkPermission(permissions, 'update'),
        allowedEmployees: getAllowedEmployees(
          establishmentsEmployees,
          ownEmployees
        ),
        ownEmployees,
        establishmentsEmployees,
        isFirstResponse: true,
      };
    }

    // ACTION FOR ACTIVITIES
    case t.INSERT_ACTIVITY:
    case t.UPDATE_ACTIVITY: {
      const filteredUnplanned = state.unplannedWorkOrders.filter(
        unplannedWorkOrder => unplannedWorkOrder.id !== payload.work_order_id
      );
      const filteredActivities = state.activities.filter(
        ({ id }) => id !== payload.id
      );

      filteredActivities.push(payload);

      return {
        ...state,
        activities: filteredActivities,
        unplannedWorkOrders: filteredUnplanned,
      };
    }

    case t.EDIT_ACTIVITY: {
      const draft = JSON.parse(JSON.stringify(state.activities));

      const activityIndex = draft.findIndex(i => i.id === payload.id);

      draft[activityIndex] = payload.updatedActivity;

      return {
        ...state,
        activities: draft,
      };
    }

    case t.DELETE_ACTIVITY: {
      if (payload.length) {
        let activities = [...state.activities];

        payload.forEach(i => {
          activities = activities.filter(({ id }) => id !== i);
        });

        return { ...state, activities };
      }

      return {
        ...state,
        activities: state.activities.filter(({ id }) => id !== payload),
      };
    }

    case t.UPDATE_DAYS_SUMMARY: {
      const updatedDates = state.dates.map(day => {
        const { date } = day;

        const updated = payload.find(updatedDay => updatedDay.date === date);

        if (updated) return updated;

        return day;
      });

      return {
        ...state,
        dates: updatedDates,
      };
    }

    case t.GET_ACTIVITIES_TYPES: {
      return {
        ...state,
        activitiesTypes: payload,
      };
    }

    case t.FETCH_ACTIVITIES_BY_SCROLLING: {
      const { activities, total_planned: dates, isRightSide } = payload;

      let scrollLeft;
      let updatedDates;
      let filteredActivities;

      const used = new Set();

      const uniqueActivities = state.activities
        .concat(activities)
        .filter(activity => {
          if (used.has(activity.id)) return false;

          used.add(activity.id);
          return true;
        });

      if (
        state.activities.length + activities.length >
        state.maxActivitiesNumber
      ) {
        scrollLeft = OFFSET_DAY_WIDTH * 11;
        updatedDates = isRightSide
          ? state.dates.slice(-14).concat(dates)
          : dates.concat(state.dates.slice(0, 14));

        filteredActivities = uniqueActivities.filter(
          ({ id, start_point: startPoint, end_point: endPoint }) =>
            id === globalMutableStore.activeId ||
            isActivityInRange({ startPoint, endPoint }, updatedDates)
        );
      } else {
        scrollLeft = 0;
        updatedDates = isRightSide
          ? state.dates.concat(dates)
          : dates.concat(state.dates);

        filteredActivities = uniqueActivities;
      }

      return {
        ...state,
        scrollLeft,
        dates: updatedDates,
        activities: filteredActivities,
      };
    }

    // ACTION FOR CALENDAR ZOOMING (ZOOM-ZOOM)
    case t.ZOOM_IN: {
      const { maxScale, scalingSpeed, scale: prevScale } = state;

      let scale = prevScale * scalingSpeed;
      if (scale > maxScale) scale = maxScale;

      const dimensions = getScaledDimensions(scale);

      return { ...state, scale, dimensions };
    }

    case t.ZOOM_OUT: {
      const { minScale, scalingSpeed, scale: prevScale } = state;
      let scale = prevScale / scalingSpeed;
      if (scale < minScale) scale = minScale;

      const dimensions = getScaledDimensions(scale);

      return { ...state, scale, dimensions };
    }

    case t.RESET_SCALE: {
      return state.scale === 1
        ? state
        : { ...state, scale: 1, dimensions: DIMENSIONS };
    }

    // ACTION FOR EMPLOYEE SORTING
    case t.UPDATE_EMPLOYEES_ORDER: {
      const visibleEmployees = payload.employees.filter(
        ({ visible }) => visible
      );

      const positionsMap = {};

      visibleEmployees.forEach(({ id }, i) => {
        positionsMap[id] = i;
      });

      return {
        ...state,
        positionsMap,
        visibleEmployees: [...visibleEmployees],
        allEmployees: payload.employees,
        employees: visibleEmployees,
        // checkedEstablishments: payload.establishments,
      };
    }

    case t.SEARCH_EMPLOYEES: {
      const positionsMap = {};

      payload.forEach(({ id }, i) => {
        positionsMap[id] = i;
      });

      return { ...state, positionsMap, employees: payload };
    }

    // ACTION FOR EMPLOYEES IN WORK ORDER
    case t.REMOVE_EMPLOYEE: {
      const { employee_id: emplId, work_order_id: workOrderId } = payload;
      const targetActivities = state.activities.filter(
        ({ employee_id }) => employee_id !== emplId
      );
      const sourceActivities = state.activities
        .filter(({ employee_id }) => employee_id === emplId)
        .filter(({ work_order_id }) => work_order_id !== workOrderId);

      return {
        ...state,
        activities: [...targetActivities, ...sourceActivities],
      };
    }

    case t.REMOVE_ALL_ACTIVITIES: {
      return {
        ...state,
        activities: state.activities.filter(
          ({ work_order_id }) => work_order_id !== payload.work_order_id
        ),
      };
    }

    // ACTION FOR "JUMP" TO FEATURE
    case t.ADD_TODAY_REF: {
      return {
        ...state,
        todayRef: payload,
      };
    }

    case t.ADD_BOARD_REF: {
      return {
        ...state,
        boardRef: payload,
      };
    }

    case t.FETCH_OUTSIDE_DATE: {
      const { activities, total_planned: dates } = payload;
      return {
        ...state,
        dates,
        activities,
      };
    }

    // ACTION FOR WORK ORDER FILTERS
    case t.GET_WORK_ORDER_FILTERS: {
      const locations = new Set();
      const customers = new Set();
      const establishments = new Set();

      if (state.activities) {
        state.activities.forEach(activity => {
          if (activity.work_order_id) {
            locations.add(activity.city);
            customers.add(activity.customer_name);
            establishments.add(activity.establishment_name);
          }
        });
      }

      return {
        ...state,
        workOrdersCustomers: Array.from(customers),
        workOrdersLocations: Array.from(locations),
        workOrdersEstablishments: Array.from(establishments),
      };
    }

    // ACTION FOR UNPLANNED WORK ORDERS
    case t.FETCH_UNPLANNED_WORK_ORDERS: {
      const locations = new Set();
      const methods = new Set();
      const customers = new Set();
      const establishments = new Set();

      payload.forEach(workOrder => {
        const { city, street, house_number: houseN } = workOrder.work_location;
        const addressLine = `${street} ${houseN}, ${city}`;

        if (workOrder.methods.length) {
          workOrder.methods.forEach(method => {
            methods.add(method);
          });
        }

        workOrder.addressLine = addressLine;
        locations.add(addressLine);
        customers.add(workOrder.customer_name);
        establishments.add(workOrder.establishment_name);
      });

      return {
        ...state,
        unplannedWorkOrders: payload,
        unplannedWorkOrdersCustomers: Array.from(customers),
        unplannedWorkOrdersLocations: Array.from(locations),
        unplannedWorkOrdersMethods: Array.from(methods),
        unplannedWorkOrdersEstablishments: Array.from(establishments),
      };
    }

    case t.REMOVE_UNPLANNED_WORK_ORDER:
    case t.UPDATE_UNPLANNED_WORK_ORDERS: {
      const filtered = state.unplannedWorkOrders.filter(
        unplWorkOrder => unplWorkOrder.id !== payload
      );
      return {
        ...state,
        unplannedWorkOrders: filtered,
      };
    }

    case t.SEARCH_UNPLANNED_ORDERS: {
      return {
        ...state,
        unplannedOrdersSearchQuery: payload,
      };
    }

    case t.CHANGE_CUSTOMER_FILTER: {
      return {
        ...state,
        customerFilters: payload,
      };
    }

    case t.CHANGE_LOCATION_FILTER: {
      return {
        ...state,
        locationFilters: payload,
      };
    }

    case t.CHANGE_METHOD_FILTER: {
      return {
        ...state,
        methodFilters: payload,
      };
    }

    case t.CHANGE_ESTABLISHMENT_FILTER: {
      return {
        ...state,
        establishmentFilters: payload,
      };
    }

    // ACTION FOR WORK ORDER FILTERS
    case t.SEARCH_WORK_ORDERS: {
      return {
        ...state,
        workOrdersSearchQuery: payload,
      };
    }

    case t.СHANGE_WO_CUSTOMER_FILTER: {
      return {
        ...state,
        workOrderCustomerFilters: payload,
      };
    }

    case t.СHANGE_WO_LOCATION_FILTER: {
      return {
        ...state,
        workOrderLocationFilters: payload,
      };
    }

    case t.CHANGE_WO_ESTABLISHMENT_FILTER: {
      return {
        ...state,
        workOrderEstablishmentFilters: payload,
      };
    }

    case t.GET_WORK_ORDERS: {
      return {
        ...state,
        workOrders: payload,
      };
    }

    case t.CREATE_RECURRING_ACTIVITY:
    case t.UPDATE_RECURRING_ACTIVITY: {
      const { id, activities } = payload;
      const { work_order_id: workOrderId } = activities[0];

      const filtered = state.activities.filter(
        activity => activity.recurring_activity_id !== id
      );

      let filteredUnplanned = [...state.unplannedWorkOrders];

      if (workOrderId)
        filteredUnplanned = state.unplannedWorkOrders.filter(
          unplannedWorkOrder => unplannedWorkOrder.id !== workOrderId
        );

      return {
        ...state,
        activities: filtered.concat(activities),
        unplannedWorkOrders: filteredUnplanned,
      };
    }

    case t.DELETE_RECURRING_ACTIVITY: {
      return {
        ...state,
        activities: state.activities.filter(
          activity => activity.recurring_activity_id !== payload
        ),
      };
    }

    case t.SET_ACTIVE_EMPLOYEE: {
      return {
        ...state,
        activeEmployee: payload,
      };
    }

    case t.SET_IS_FILE_LOADING: {
      return {
        ...state,
        isFileLoading: payload,
      };
    }

    case t.ADD_ATTACHED_FILE_ID: {
      return {
        ...state,
        attachedFiles: state.attachedFiles.concat(payload),
      };
    }

    case t.DELETE_ATTACHED_FILE_ID: {
      const updAttachedFiles = state.attachedFiles.filter(
        ({ fileId }) => fileId !== payload
      );
      return {
        ...state,
        attachedFiles: updAttachedFiles,
      };
    }

    case t.DELETE_ALL_FILES: {
      return {
        ...state,
        attachedFiles: [],
      };
    }

    case t.UPDATED_WORK_ORDER_NOTE: {
      const draft = JSON.parse(JSON.stringify(state.activities));
      const newNote = payload?.note_content;
      const workOrderToUpdate = draft.find(
        i => i.work_order_id === payload.work_order_id
      );

      if (newNote) {
        workOrderToUpdate.note = { ...workOrderToUpdate, content: newNote };
      } else {
        workOrderToUpdate.note = null;
      }

      return {
        ...state,
        activities: draft,
      };
    }

    default:
      return state;
  }
};
